@use 'themes' as *;
@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
};

@import "normalize.css";
@import "reset-css";
@import "global/global";

body {
  font-family: 'Causten Round', sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
}

/* You can add global styles to this file, and also import other style files */
