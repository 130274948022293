.form {
  padding: 1.5rem;
  border-radius: 0 1.875rem 1.875rem 1.875rem;
  background: transparent;
  border: 1.6px solid #fff;
}

.title {
  font-size: 1rem;
  line-height: 1.125rem;
  margin-bottom: 0.5rem;
  margin-left: 0.8rem;
  font-weight: 500;
  color: nb-theme(text-color);

  &.dark {
    color: nb-theme(text-color-content);
  }

  &.required::before {
    content: '*';
  }
}

.date-time {
  display: flex;
  gap: 0.75rem;
}

.form-group {
  margin-bottom: 1.5rem;

  &.full-width {
    width: 100%;
  }

  &.tiny {
    max-width: 10.5rem;
    width: 100%;
  }

  &.short {
    max-width: 15.25rem;
    width: 100%;
  }

  &.medium {
    max-width: 16.75rem;
    width: 100%;
  }

  &.long {
    max-width: 26rem;
    width: 100%;
  }
}

.date-error {
  align-items: flex-start;
  color: var(--color-danger-500);
  display: flex;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;

  nb-icon {
    font-size: 1rem;
    margin-right: 0.375rem;
  }
}

nb-checkbox label.label {
  cursor: pointer !important;
}
