@forward 'node_modules/@nebular/theme/styles/theming';
@use 'node_modules/@nebular/theme/styles/theming' as *;
@use 'node_modules/@nebular/theme/styles/themes/default';
@use 'sass:string';

/* stylelint-disable color-function-notation, alpha-value-notation */

$nb-themes: nb-register-theme(
  (
    // custom color-vars
    text-placeholder-color: #afbfde,
    text-color: #ffffff,
    text-color-content: color-primary-blue,
    // colors
    color-primary-orange: #ff9d00,
    color-primary-blue: #004073,

    //layout
    layout-background-color: #fff,
    layout-padding: 0,
    layout-medium-padding: 0,
    layout-small-padding: 0,

    //checkbox
    checkbox-basic-background-color: rgba(143, 155, 179, 0.5),
    checkbox-text-font-size: 1rem,
    checkbox-text-line-height: 1.125rem,
    checkbox-text-font-weight: 500,
    checkbox-basic-text-color: text-color,
    checkbox-basic-checked-background-color: color-primary-blue,
    checkbox-basic-checked-border-color: color-primary-blue,
    checkbox-basic-indeterminate-background-color: color-primary-blue,
    checkbox-basic-indeterminate-border-color: color-primary-blue,

    //select
    select-min-width: 5rem,

    //button
    button-medium-text-font-size: 1.125rem,
    button-filled-basic-text-color: text-color,
    button-filled-basic-background-color: color-primary-orange,
    button-filled-basic-border-color: color-primary-orange,

    button-filled-basic-focus-background-color: #FFB339,
    button-filled-basic-focus-border-color: #FFB339,

    button-filled-basic-hover-background-color: #FFB339,
    button-filled-basic-hover-border-color: #FFB339,

    button-filled-basic-active-background-color: #FFB339,
    button-filled-basic-active-border-color: #FFB339,

    button-filled-basic-disabled-background-color: #C67A00,
    button-filled-basic-disabled-border-color: #C67A00,
    button-filled-basic-disabled-text-color: #9B6000,

    //typography,
    font-family-primary: string.unquote('Causten Round, sans-serif'),
    text-label-font-family: font-family-primary,
    layout-text-font-size: 1.125rem,
    text-basic-color: color-primary-blue,

    //stepper
    stepper-step-text-color: text-color,
    stepper-step-text-font-family: font-family-primary,
    stepper-step-text-font-size: 1rem,
    stepper-step-text-font-weight: 500,
    stepper-step-active-text-color: color-primary-blue,
    stepper-step-index-active-border-color: text-color


  ),
  default,
  default
);
