@font-face {
  font-family: 'Causten Round Light Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Medium Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Black Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-BlackOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Ex Bold Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-ExtraBoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Semi Bold Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-SemiBoldOblique.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Extra';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-RegularOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Semi';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Bold Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Thin Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-ThinOblique.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Extra';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round Ex Light Oblique';
  src: url('../../../../assets/fonts/causten_round/CaustenRound-ExtraLightOblique.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

